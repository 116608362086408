import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Compliance/PageBanner-compliance'
import Footer from "../components/App/Footer"
import banner from '../assets/images/compliance/compliance-main4.png'
import ComplianceDetails from "../components/Compliance/Compliance"
// import IntegrationTemplates from "../components/Home/IntegrationTemplates"
// import StartProject from '../components/Common/StartProject'

const Compliance = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Compliance" 
                homePageText="ekko’s approach helps you develop a company-wide integrated program to address your compliance requirements, whether driven by regulatory, legal, or customer related concerns" 
                homePageUrl="/" 
                screenShot={banner}
                activePageText="ekko’s approach helps you develop a company-wide integrated program to address your compliance requirements, whether driven by regulatory, legal, or customer related concerns" 
            />
             <ComplianceDetails />
            {/*<IntegrationTemplates />
            <StartProject /> */}
            <Footer />
        </Layout>
    );
}

export default Compliance